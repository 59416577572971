.root {
  @apply flex;
  @apply justify-center;
  @apply items-center;
  @apply transition-all;
  @apply py-4;
  @apply px-4;
  @apply my-1;

  &Outlined {
    @apply border-theme-main;
    @apply border-opacity-25;
    @apply border-2;
    @apply text-theme-main;
    @apply shadow-none;
    @apply bg-light;

    &:hover {
      @apply bg-gradient-to-r;
      @apply from-theme-gradient-from;
      @apply to-theme-gradient-to;
      @apply text-light;
      @apply shadow-lg;
      @apply border-opacity-100;
      @apply shadow-theme-main/25;
    }

    &:disabled {
      @apply text-muted;
      @apply border-card-default;
      @apply border-opacity-100;

      &:hover {
        @apply cursor-pointer;
        @apply bg-light;
        @apply text-muted;
        @apply shadow-none;
        @apply cursor-not-allowed;
      }
    }
  }

  &Contained {
    @apply bg-gradient-to-r;
    @apply from-theme-gradient-from;
    @apply to-theme-gradient-to;
    @apply text-light;
    @apply border-theme-main;
    @apply border-2;
    @apply shadow-lg;
    @apply shadow-theme-main/20;

    &:hover {
      @apply shadow-theme-main/40;
      @apply bg-opacity-95;
    }

    &:disabled {
      background-image: inherit;
      @apply bg-card-default;
      @apply border-card-default;
      @apply text-muted;
    }
  }

  &IconPrefix {
    @apply mr-2;
    @apply scale-75;
  }

  &IconSuffix {
    @apply ml-2;
    @apply scale-75;
  }

  &:disabled {
    @apply shadow-none;
    @apply cursor-not-allowed;
  }

  @screen md {
    @apply px-4;
    @apply py-2;
    @apply my-0;
    @apply justify-between;
  }
}
