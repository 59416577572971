.root {
  @apply bg-error;
  @apply bg-opacity-90;
  @apply text-light;
  @apply p-8;
  @apply rounded-xl;
  @apply m-4;
  @apply relative;
  @apply max-w-md;
}
