.root {
  @apply flex;
  @apply items-center;
}

.wrapper {
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply rounded-full;
  @apply text-subcategory;
  @apply border-2;
  @apply m-2;

  &Current {
    @apply border-theme-main;
    @apply bg-transparent;
    @apply text-theme-main;
    @apply w-8;
    @apply h-8;
  }

  &Less {
    @apply border-theme-main;
    @apply bg-gradient-to-r;
    @apply from-theme-gradient-from;
    @apply to-theme-gradient-to;
    @apply text-light;
    @apply w-4;
    @apply h-4;
  }

  &Greater {
    @apply border-card-hover;
    @apply bg-transparent;
    @apply text-card-hover;
    @apply w-4;
    @apply h-4;
  }

  @screen md {
    @apply w-12;
    @apply h-12;
  }
}

.item {
  @apply hidden;
  @screen md {
    @apply block;
  }
}
