#nprogress {
  pointer-events: none;

  .bar {
    @apply bg-theme-main;
    height: 2px;
    left: 0;

    position: fixed;
    top: 0;

    width: 100%;
    z-index: 1031;
  }

  .peg {
    @apply shadow-md;
    @apply shadow-theme-main;

    display: block;
    height: 100%;
    opacity: 1;
    position: absolute;
    right: 0px;

    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
    width: 100px;
  }

  .spinner {
    display: block;
    position: fixed;
    right: 15px;
    top: 15px;
    z-index: 1031;
  }

  .spinner-icon {
    -webkit-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite;

    border: solid 2px transparent;
    border-radius: 50%;
    box-sizing: border-box;
    height: 18px;
    width: 18px;
    @apply border-l-theme-main;
    @apply border-t-theme-main;
  }
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
