.root {
  @apply flex;
  @apply items-center;
  @apply flex-col;
  @apply mt-4;
  @apply order-3;

  @screen md {
    @apply mt-0;
    @apply order-2;
  }

  &Wrapper {
    @apply flex;
    @apply items-center;
  }
}
