.root {

  &BackgroundWrapper {

    @screen md {
      @apply bg-card-hover;
      @apply flex;
      @apply items-end;
      height: 192px;
    }
  }

}
