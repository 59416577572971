.root {
  @apply select-none;
  @apply text-body;
  @apply font-medium;
  @apply rounded;
  @apply whitespace-nowrap;

  &Padding {
    @apply px-6;
    @apply py-2;
  }
}
