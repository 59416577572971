.datepicker {
  @apply w-full;

  .MuiOutlinedInput-notchedOutline {
    border-width: 0 !important;
  }

  fieldset {
    @apply border-0;
    @apply p-0;

    &.MuiOutlinedInput-notchedOutline {
      @apply border-0;
    }
    &:focus {
      @apply border-0;
    }
  }

  input {
    @apply p-0;

    &::placeholder {
      @apply uppercase;
      @apply text-small;
      @apply placeholder-dark;
    }
  }

  .MuiInputAdornment-positionEnd {
    @apply absolute;
    @apply pl-1;
  }
}

.Mui-selected {
  background-color: #cf9720 !important;
  display: flex;
  justify-content: center;
  @apply bg-theme-gradient-from;
  @apply pt-0.5;
  @apply items-center;
}

.MuiPickersSlideTransition-root {
  min-height: 205px !important;
}

.MuiButtonBase-root.MuiPickersDay-root {
  padding-top: 2px !important;

  &:hover {
    @apply bg-card-hover;
  }
}

.MuiPickersYear-yearButton:hover {
  @apply bg-card-hover;
}

.MuiButton-root {
  @apply text-theme-main;
  @apply font-bold;
}
