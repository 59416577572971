@font-face {
  font-display: swap;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 300;
  src:
    url(./fonts/Jost-Light.woff2) format('woff2'),
    url(./fonts/Jost-Light.ttf) format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  src:
    url(./fonts/Jost-Regular.woff2) format('woff2'),
    url(./fonts/Jost-Regular.ttf) format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  src:
    url(./fonts/Jost-Medium.woff2) format('woff2'),
    url(./fonts/Jost-Medium.ttf) format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 700;
  src:
    url(./fonts/Jost-Bold.woff2) format('woff2'),
    url(./fonts/Jost-Bold.ttf) format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Prata';
  font-style: normal;
  font-weight: 400;
  src:
    url(./fonts/Prata-Regular.woff2) format('woff2'),
    url(./fonts/Prata-Regular.ttf) format('truetype');
}

@tailwind base;
@tailwind utilities;

.react-loading-skeleton {
  --base-color: theme('colors.card.hover');
  --highlight-color: theme('colors.card.default');
}
@import '~react-loading-skeleton/dist/skeleton.css';

@import 'nprogress';
@import 'accordion';
@import 'calendar';
@import 'datepicker';

@layer base {
  html,
  body {
    @apply bg-background;
  }
}
