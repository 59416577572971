.root {
  @apply order-2;
  @apply self-end;
  @apply absolute;
  @apply text-muted;
  @apply top-20;
  @apply right-12;

  @screen md {
    @apply order-3;
    @apply self-auto;
    @apply static;
  }
}
