.calendar {
  &-controls {
    @apply flex;
    @apply justify-between;
    @apply items-center;

    .current-year {
      @apply text-body;
    }

    .control {
      &-previous,
      &-next {
        @apply text-muted;
        @apply bg-card-default;
        @apply w-[40px];
        @apply h-[40px];
        @apply p-4;
        @apply flex;
        @apply items-center;
        &:hover {
          @apply bg-card-hover;
        }

        &:disabled {
          @apply bg-transparent;
          @apply text-card-hover;
        }
      }
    }
  }

  &-content {
    @apply flex;
    @apply gap-12;

    .wrapper {
      @apply flex-1;

      &.secondary {
        @apply hidden;
      }
    }

    @screen lg {
      .wrapper {
        &.secondary {
          @apply block;
        }
      }

    }

    .weekContainer {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
    }

    .day {
      @apply flex;
      @apply flex-col;
      @apply items-center;
      @apply cursor-pointer;
      @apply py-2;
      @apply my-1;
      @apply select-none;
      @apply focus:outline-none;

      &:hover {
        &:not(.from, .to, .disabledDate) {
          @apply rounded-xl;
          &.available {
            @apply bg-primary-highlight;
            @apply bg-opacity-30;
          }
          &.demand {
            @apply bg-info-highlight;
            @apply bg-opacity-30;
          }
        }
      }

      span {
        @apply text-dark;
        @apply font-medium;
        @apply select-none;
      }

      .price {
        @apply text-caption;
        width: 32px;
        @apply whitespace-nowrap;
        @apply overflow-hidden;
        @apply text-center;

        @screen xs {
          @apply w-auto;
        }

        @screen md {
          @apply rounded-xl;
          @apply py-1;
          @apply px-1.5;
          @apply w-auto;
        }

        span {
          @apply font-medium;
          display: block;
          overflow: hidden;
          position: relative;
          white-space: nowrap;
          width: 100%;

          &::after {
            content: '';
            @apply absolute;
            @apply top-0;
            @apply right-0;
            @apply bottom-0;
            width: 10%;
            @apply bg-gradient-to-r;
            @apply from-transparent;
            @apply to-light;
          }
        }

        /* Override span::after for medium screens and larger */
        @screen xs {
          span::after {
            content: none;
          }
        }

      }

      &.available {
        .price {

          @screen md {
            @apply bg-primary-highlight;
          }

          span {
            @apply text-primary-main;

          }
        }
      }

      &.demand {
        .price {

          @screen md {
            @apply bg-info-highlight;
          }

          span {
            @apply text-info-main;
          }
        }
      }

      &.notAvailable {
        .price {

          @screen md {
            @apply bg-danger-highlight;
          }

          span {
            @apply text-danger-main;
          }
        }
      }

      &.disabledDate {
        @apply cursor-default;
        @apply bg-transparent;

        span {
          @apply text-card-hover;
        }

        &.available {
          .price {
            @apply bg-opacity-20;
            span {
              @apply text-primary-main;
            }
          }
        }

        &.demand {
          .price {
            span {
              @apply text-info-main;
            }
          }
        }

        &.notAvailable {
          .price {
            span {
              @apply text-danger-main;
            }
          }
        }
      }

      &.from {
        @apply rounded-l-xl;
        &:hover {
          @apply rounded-l-xl;
        }

        &.available {
          @apply bg-primary-highlight;
          span {
            @apply text-dark;
          }
          .price {
            span {
              @apply text-primary-main;

              &::after {
                @apply bg-gradient-to-r;
                @apply from-transparent;
                @apply to-primary-highlight;
              }
            }
          }
        }

        &.demand {
          @apply bg-info-highlight;
          span {
            @apply text-dark;
          }
          .price {
            span {
              @apply text-info-main;

              &::after {
                @apply bg-gradient-to-r;
                @apply from-transparent;
                @apply to-info-highlight;
              }
            }
          }
        }
      }

      &.to {
        @apply rounded-r-xl;
        &:hover {
          @apply rounded-r-xl;
        }

        &.available {
          @apply bg-primary-highlight;
          span {
            @apply text-dark;
          }
          .price {
            span {
              @apply text-primary-main;

              &::after {
                @apply bg-gradient-to-r;
                @apply from-transparent;
                @apply to-primary-highlight;
              }
            }
          }
        }

        &.demand {
          @apply bg-info-highlight;
          span {
            @apply text-dark;
          }
          .price {
            span {
              @apply text-info-main;

              &::after {
                @apply bg-gradient-to-r;
                @apply from-transparent;
                @apply to-info-highlight;
              }
            }
          }
        }
      }

      &.select {
        &:not(.isNotSameMonth, .available, .demand) {
          @apply bg-card-hover;
          @apply bg-opacity-20;
          span {
            @apply text-dark;
          }
        }

        &:not(.from, .to, .isNotSameMonth) {
          span {
            @apply text-dark;
          }

          &.available {
            @apply bg-primary-highlight;
            @apply bg-opacity-60;
            .price {
              @apply bg-transparent;
              span {
                @apply text-primary-main;

                &::after {
                  @apply bg-gradient-to-r;
                  @apply from-transparent;
                  @apply to-primary-highlight;
                  opacity: 0.6;
                }
              }
            }
          }

          &.demand {
            @apply bg-info-highlight;
            @apply bg-opacity-60;
            .price {
              @apply bg-transparent;
              span {
                @apply text-info-main;

                &::after {
                  @apply bg-gradient-to-r;
                  @apply from-transparent;
                  @apply to-info-highlight;
                  opacity: 0.6;
                }
              }
            }
          }

          &:hover {
            @apply rounded-none;
          }
        }
      }

      &.today {
        .price {
          span {
            @apply font-medium;
          }
        }
      }

      &.isNotSameMonth {
        span {
          @apply select-none;
          @apply text-transparent;
        }
      }
    }

    .dayName {
      @apply flex;
      @apply justify-center;
      @apply items-center;
      @apply my-4;
      @apply text-muted;
      @apply uppercase;
    }
  }
}
