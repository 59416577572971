.root {
  @apply fixed;
  @apply left-0;
  @apply bottom-0;
  @apply z-50;

  &ButtonRefresh {
    @apply mt-4
  }
}
