.root {
  &Wrapper {
    @apply flex;
    @apply flex-wrap;
    @apply justify-center;
    @apply items-baseline;
    @apply font-medium;
    @apply text-small;
    @apply text-muted;
    @apply py-6;

    @screen sm {
      @apply flex-nowrap;
      @apply justify-between;
    }
  }

  &Copyright {
    @apply w-full;
    @apply uppercase;
    @apply text-center;
    @screen sm {
      @apply w-fit;
      @apply text-left;
    }
  }

  &Navigation {
    @apply flex;
    @apply space-x-2;
    @apply text-small;
    @apply justify-center;
    @apply flex-wrap;
    @apply space-x-2;
    @apply my-3.5;

    a {
      @apply text-small;

      &:hover {
        @apply text-theme-main;
      }
    }

    a:not(:first-of-type):before {
      content: '•';
      vertical-align: middle;
      @apply text-body;
      @apply pr-1;
    }

    @screen sm {
      @apply mt-0;
    }
  }
}
