.root {
  &Body {
    @apply text-body-mobile;
    @screen md {
      @apply text-body;
    }
  }

  &Caption {
    @apply text-caption;
  }

  &Category {
    @apply text-category-mobile;
    @screen md {
      @apply text-category;
    }
  }

  &Headline {
    @apply text-headline-mobile;
    @screen md {
      @apply text-headline;
    }
  }

  &Small {
    @apply text-small;
  }

  &ExtraSmall {
    @apply text-extra-small;
  }

  &Subcategory {
    @apply text-subcategory-mobile;
    @screen md {
      @apply text-subcategory;
    }
  }
}
