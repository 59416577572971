.root {
  @apply absolute;
  @apply p-1;
  @apply top-1;
  @apply right-1;
  @apply rounded-2xl;
  &:hover {
    @apply bg-light;
    @apply bg-opacity-20;
  }
}
