.root {
  @apply w-full;
  @apply py-6;

  &Shadow {
    @apply bg-light;
    @apply drop-shadow-sm;
    @apply shadow-lg;
    @apply shadow-[#C1D0D71A];
  }
}

.wrapper {
  @apply flex;
  @apply flex-col;
  @apply items-center;
  @apply justify-between;

  @screen md {
    @apply flex-row;
  }
}
