.root {
  @apply w-full;
  @apply mx-auto;

  &Normal {
    max-width: 1176px;
  }

  &Small {
    max-width: 700px;
  }

  &Padding {
    padding: 0 20px;
  }

  @screen md {
    &Padding {
      padding: 0 48px;
    }
  }

  @screen xl {
    &Padding {
      padding: 0 24px;
    }
  }
}
