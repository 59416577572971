.accordion--component {
  @apply w-full;
  @apply rounded-2xl;
  @apply bg-card-default;
  @apply my-3;
  @apply transition-all;

  &.disabled {
    @apply bg-light;
    @apply border-card-default;
  }

  &.active {
    @apply bg-light;
    @apply border-2;
    @apply border-theme-main;
    @apply border-opacity-10;
  }

  &.open {
    @apply bg-light;
    @apply border-0;
    @apply drop-shadow-xl;
  }

  .header {
    @apply w-full;
    @apply text-category;
    @apply select-none;
    @apply flex;
    @apply items-center;
    @apply justify-between;
    @apply py-3;
    @apply px-2;

    .left-side {
      @apply flex;
      @apply items-center;
      @apply relative;

      &:not(.without-action) {
        width: calc(100% - 48px);
      }

      &:is(.without-action) {
        @apply w-full;
      }

      button {
        @apply w-full;
        @apply outline-0;
        @apply border-0;
        @apply text-left;
        @apply flex;
        @apply items-center;
        @apply text-muted;

        &:disabled {
          @apply text-card-hover;
        }

        &.active {
          @apply text-theme-main;
        }

        .icon {
          @apply w-6;
          @apply h-6;
        }

        .heading {
          width: calc(100% - 24px);
          @apply whitespace-nowrap;
          @apply overflow-hidden;
          @apply ml-2;
        }
      }
    }

    .right-side {
      @apply w-12;
      @apply h-12;

      button {
        @apply w-full;
        @apply h-full;
        @apply flex;
        @apply items-center;
        @apply justify-center;
        @apply text-theme-main;
        @apply bg-theme-transparent;
        @apply rounded-xl;
      }
    }
  }

  &.active,
  &.open {
    .header {
      .left-side {
        &:not(.loading) {
          :after {
            @apply content-[''];
            @apply absolute;
            @apply right-0;
            @apply top-0;
            @apply w-12;
            @apply h-full;
            @apply bg-gradient-to-r;
            @apply from-transparent;
            @apply to-light;
          }
        }
      }
    }
  }

  .main-content {
    @apply w-full;
    @apply px-3;
    @apply pb-3;
  }

  @screen md {
    @apply my-6;

    .header {
      @apply py-4;
      @apply px-4;

      .left-side {
        .main-button {
          .heading {
            @apply ml-4;
          }
        }
      }
    }

    .main-content {
      @apply px-4;
      @apply pb-4;
    }
  }
}
