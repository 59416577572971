.root {
  @apply w-full;
  @apply bg-background;

  &BackgroundGradient {
    @screen md {
      background: linear-gradient(
        90deg,
        theme('colors.background') 80%,
        theme('colors.card.background') 0%
      );
    }
    @screen lg {
      background: linear-gradient(
        90deg,
        theme('colors.background') 60%,
        theme('colors.card.background') 0%
      );
    }
  }
}
